import React from "react";
import "./terms.css";
import Page_header from "../page-header";

const Terms = () => {
  return (
    <section className="terms-sec">
      <Page_header
        headertitle="Terms and Conditions"
        imgsrc="/assets/img/selfmade/Images/terms.png"
      />
      <div className="privacyInfo-center">
        <h2>
          Extended Trust <span>Terms and Conditions</span>
        </h2>
        {/* <p>
          <strong>Type of website:</strong> Service Based
        </p> */}
        <p>
          <strong>Effective date:</strong> November 29th, 2023
        </p>
        <p>
          <strong>
            If you have any questions or concerns about this Privacy Policy or
            our privacy practices, please contact us at:
            <br />
          </strong>
          <a href="mailto:support@extendedtrust.ca" target="_blank">
            support@extendedtrust.ca
          </a>
          <br />
          1 888 418 1711
          <br />
          Unit 200-11729 105 Ave. Edmonton, AB T5H0L9
        </p>
        <h4>Introduction</h4>
        <p>
          Welcome to Extended Trust. These terms and conditions ("Terms") govern
          your use of our website and services. By accessing or using our
          website, you agree to be bound by these Terms. Please read them
          carefully.
        </p>

        <h4>Our Services</h4>
        <p>
          Extended Trust delivers unrivaled vehicle protection programs in
          Canada. Our comprehensive programs are designed to protect your
          vehicle and ensure its peak condition. We specialize in providing
          peace of mind through top tier vehicle protection programs tailored
          for the Canadian market.
        </p>
        <h4>
          Acceptance of <span>Terms</span>
        </h4>
        <p>
          By using our website or services, you agree to these Terms. If you do
          not agree with these Terms, please do not use our website or services.
        </p>
        <h4>Privacy Policy</h4>
        <p>
          Your use of our website and services is also governed by our Privacy
          Policy, which can be found{" "}
          <a href="/privacy-policy" className="pulse-anchor">
            here
          </a>
          . Please review our Privacy Policy to understand how we collect, use,
          and protect your personal information.
        </p>
        <h4>User Registration</h4>
        <p>
          Some areas of our website may require you to register an account. You
          are responsible for maintaining the confidentiality of your account
          information and password. You agree to notify us immediately of any
          unauthorized use of your account.
        </p>
        <h4>
          Use of <span>Our Services</span>
        </h4>
        <p>
          You agree to use our services for lawful purposes and in accordance
          with these Terms. You may not use our services to engage in any
          illegal activities or violate the rights of others.
        </p>
        <h4>Intellectual Property</h4>
        <p>
          All content and materials on our website, including but not limited to
          text, images, logos, and trademarks, are the property of Extended
          Trust or its licensors and are protected by copyright and other
          intellectual property laws.
        </p>
        <h4>
          Limitation of <span>Liability</span>
        </h4>
        <p>
          Extended Trust is not liable for any damages, whether direct,
          indirect, incidental, special, or consequential, arising out of or in
          any way connected with the use of our website or services.
        </p>
        <h4>Governing Law</h4>
        <p>
          These Terms are governed by the laws of Alberta, Canada. Any disputes
          arising from or relating to these Terms shall be subject to the
          exclusive jurisdiction of the courts in Alberta.
        </p>
        <h4>Contact Us</h4>
        <p>
          If you have any questions or concerns about these Terms or our
          services, please contact us at{" "}
          <a
            style={{ fontWeight: "bold" }}
            href="mailto:support@extendedtrust.ca"
            target="_blank"
          >
            support@extendedtrust.ca
          </a>{" "}
          or use the contact information provided above.
        </p>
      </div>
    </section>
  );
};

export default Terms;
